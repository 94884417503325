










import { Component, Vue } from 'vue-property-decorator'

import PageTitle from '@/components/PageTitle.vue'
import AccountLocked from '@/components/AccountLocked.vue'

@Component({
  components: { AccountLocked, PageTitle },
})
export default class MultiFAView extends Vue {}
