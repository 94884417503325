import honeybadger from 'honeybadger-js'
import FeatureFlags from '@/util/featureFlags'
import { Locale } from '@/i18n'

declare global {
  interface Window {
    honeybadger: typeof honeybadger
  }
}

export default class PPSession {
  readonly csrfToken: string
  readonly userId?: string
  readonly adminId?: string
  readonly timeoutInMs?: number
  readonly isMFAAuthenticated?: boolean
  readonly locale: string

  constructor(
    csrfToken: string,
    userId?: string,
    adminId?: string,
    timeoutInMs?: number,
    isMFAAuthenticated?: boolean,
    locale?: string,
  ) {
    this.csrfToken = csrfToken
    this.userId = userId
    this.adminId = adminId
    this.timeoutInMs = timeoutInMs
    this.isMFAAuthenticated = !!isMFAAuthenticated
    this.locale = locale || Locale.ENGLISH
  }

  static async fromObject(obj: any): Promise<PPSession | undefined> {
    const csrfToken = obj.csrf_token
    const userId = obj.user_id
    const adminId = obj.admin_id
    const timeoutInMs = obj.timeout_in_ms
    const featureFlags = obj.feature_flags
    const isMFAAuthenticated = !!obj.finished_authentication
    const locale = obj.locale

    await FeatureFlags.set(featureFlags || [])

    // must be a string
    if (csrfToken && typeof csrfToken !== 'string') {
      if (window.honeybadger) {
        window.honeybadger.notify(`PPSession: csrfToken has a illegal type ${typeof csrfToken}`)
      }
      return undefined
    }

    // must be undefined or a string
    if (userId && typeof userId !== 'string') {
      if (window.honeybadger) {
        window.honeybadger.notify(`PPSession: userId has a illegal type ${typeof userId}`)
      }
      return undefined
    }

    // must be undefined or a string
    if (adminId && typeof adminId !== 'string') {
      if (window.honeybadger) {
        window.honeybadger.notify(`PPSession: adminId has a illegal type ${typeof adminId}`)
      }
      return undefined
    }

    return new PPSession(csrfToken, userId, adminId, timeoutInMs, isMFAAuthenticated, locale)
  }
}
