

























import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import User from '@/models/User'
import Loading from '@/components/Loading.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import AddRxMedHeader from '@/components/medications/addRx/MedHeader.vue'
import { MedOption } from '@/vuex/addRx'

@Component({
  components: {
    Loading,
    LinkArrowIcon,
    AddRxMedHeader,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapState('addRx', ['med']),
    ...mapGetters('addRx', ['medName', 'availableStrengths']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class MedicationStrength extends Vue {
  me!: User
  med!: MedOption
  medName!: string
  availableStrengths!: PrescriptionStrength[]

  @Watch('med', { immediate: true })
  onMedChange() {
    if (this.med?.type === 'NAME_AND_FORM') {
      this.getStrengths()
    }
  }

  getStrengths() {
    const userId = this.me.id
    this.$store.dispatch('addRx/loadMedStrengths', { userId })
  }

  select(strength: PrescriptionStrength) {
    this.$store.commit('addRx/selectMedStrength', { strength })
    this.$router.push({
      name: 'prescriber-select',
    })
  }

  selectUnknown() {
    const name = this.medName
    this.$store.commit('addRx/selectUnknownMedStrength', { name })
    this.$router.push({
      name: 'prescriber-select',
    })
  }

  async created() {
    await this.$store.dispatch('user/ensureMe')
  }
}
