import featureFlags from './featureFlags'

let amazonChatEnabled = false

async function updateFeatureFlag() {
  amazonChatEnabled = await featureFlags.enabled('amazon-chat')
}

updateFeatureFlag()

export default function amazonZendeskUrlFF(amazonUrl: string, zendeskUrl: string): string {
  if (amazonChatEnabled) {
    return amazonUrl
  }
  return zendeskUrl
}
