var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"id":"med-preferences-info","title":_vm.$t('medPreferencesModal.title'),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('ul',{staticClass:"bullet-list"},[(!_vm.prescription.packetable)?_c('li',{attrs:{"data-testid":"not-eligible-bullet"}},[_c('i18n',{attrs:{"path":"medPreferencesModal.bullets._notEligibleForPackets"}},[_c('a',{attrs:{"href":_vm.amazonZendeskUrlFF(
                'https://www.amazon.com/gp/help/customer/display.html?nodeId=TRwW22J1sHM7vhr83d',
                'https://www.amazon.com/gp/help/customer/display.html?nodeId=THPj1oRuVuVHhAuZSp'
              ),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('Why?'))+" ")])])],1):_c('li',{attrs:{"data-testid":"auto-refill-bullet"}},[_vm._v(" "+_vm._s(_vm.$t('medPreferencesModal.bullets.autoRefill'))+" ")]),(_vm.ninetyDayAvailable)?_c('li',{attrs:{"data-testid":"90-day-available-bullet"}},[_vm._v(" "+_vm._s(_vm.$t('medPreferencesModal.bullets.ninetyDayAvailable'))+" ")]):_vm._e(),(_vm.prescription.dispenseDaysSupply === 90)?_c('li',{attrs:{"data-testid":"30-day-available-bullet"}},[_vm._v(" "+_vm._s(_vm.$t('medPreferencesModal.bullets.thirtyDayAvailable'))+" ")]):_vm._e()]),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('medPreferencesModal.contactUs'))+" ")]),_c('ChatLink',{staticClass:"mr-4",attrs:{"data-testid":"open-chat-button"},nativeOn:{"click":function($event){return close($event)}}},[_c('icon-livechat-small',{staticClass:"stroke-blue mr-1 pb-1"}),_c('strong',[_vm._v(_vm._s(_vm.$t('Chat')))])],1),_c('b-link',{attrs:{"href":_vm._f("tel.link")(_vm.me.supportPhone),"data-testid":"support-phone-number"}},[_c('icon-phone-small-outline',{staticClass:"stroke-blue mr-1 pb-1"}),_c('strong',[_vm._v(_vm._s(_vm._f("tel.nowrap")(_vm.me.supportPhone)))])],1)]}},{key:"modal-footer",fn:function(ref){
              var close = ref.close;
return [_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":close}},[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }