











































import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import Card from '@/components/Card.vue'
import amazonZendeskUrlFF from '@/util/amazonZendeskUrlFF'

@Component({
  components: { Modal, Card },
})
export default class rxDeliveryInfoModal extends Vue {
  amazonZendeskUrlFF = amazonZendeskUrlFF
  get copy() {
    return {
      header: this.$t('rxDelivery.header'),
      body: this.$t('rxDelivery.body'),
      header2: this.$t('rxDelivery.header2'),
      items: [
        {
          header: this.$t('rxDelivery.item1.header'),
          body: this.$t('rxDelivery.item1.body'),
        },
        {
          header: this.$t('rxDelivery.item2.header'),
          body: this.$t('rxDelivery.item2.body'),
        },
        {
          header: this.$t('rxDelivery.item3.header'),
          body: this.$t('rxDelivery.item3.body'),
        },
      ],
      footerNote: this.$t('rxDelivery.footerNote'),
      footerBody: this.$t('rxDelivery.footerBody'),
      learnMore: this.$t('rxDelivery.learnMore'),
    }
  }

  close() {
    this.$bvModal.hide('rx-delivery-info-modal')
  }
}
