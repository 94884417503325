import PPClientModule from './PPClientModule'
import PPUserApi from './PPUsersApi'

export default class PPUsersClient extends PPClientModule {
  me = this.wrapApi(PPUserApi.me)

  user = this.wrapApi(PPUserApi.getUser)

  onboardState = this.wrapApi(PPUserApi.getOnboardState)

  updateUser = this.wrapApi(PPUserApi.update)

  updatePassword = this.wrapApi(PPUserApi.updatePassword)

  addCaregiver = this.wrapApi(PPUserApi.addCaregiver)

  removeCaregiver = this.wrapApi(PPUserApi.removeCaregiver)

  resendEmailConfirmation = this.wrapApi(PPUserApi.resendEmailConfirmation)

  updateSmsPreference = this.wrapApi(PPUserApi.updateSmsPreference)

  updatePhiInCommunicationsPreference = this.wrapApi(PPUserApi.updatePhiInCommunicationsPreference)

  updateChildSafePackaging = this.wrapApi(PPUserApi.updateChildSafePackaging)

  updateGoPaperless = this.wrapApi(PPUserApi.updateGoPaperless)

  updateUserIdentification = this.wrapApi(PPUserApi.updateUserIdentification)
}
