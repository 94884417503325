import Vue from 'vue'
import { RouteConfig } from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import LoginView from '@/views/LoginView.vue'
import LogoutView from '@/views/LogoutView.vue'
import PasswordResetView from '@/views/PasswordResetView.vue'
import CaregiverView from '@/views/CaregiverView.vue'
import HelpView from '@/views/HelpView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import MultiFAView from '@/views/MultiFAView.vue'
import getCurrentUser from '@/util/getCurrentUser'
import AccountLockedView from '@/views/AccountLockedView.vue'
import { $t } from '@/i18n'
import FeatureFlags from './util/featureFlags'

enum Layouts {
  Blank = 'Blank',
  Default = 'Default',
  NoNavbar = 'NoNavbar',
  Print = 'Print',
}

const routes: RouteConfig[] = [
  // Async routes are lazy loaded and pre-cached
  // Critical Path routes will be included in main chunk
  {
    path: '/caregiver',
    name: 'caregiver',
    component: CaregiverView,
    meta: {
      requireSession: true,
      requireAuthentication: true,
      layout: Layouts.NoNavbar,
      title: () => $t('Switch account'),
    },
    beforeEnter: (to, from, next) => {
      const currentUser = getCurrentUser()
      if (currentUser && !currentUser.isCareGiver) {
        return next({ name: 'dashboard' })
      }

      return next()
    },
  },
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('Home'),
    },
  },
  {
    path: '/alerts',
    name: 'service-alerts',
    component: () =>
      import(/* webpackChunkName: "service-alerts" */ '@/views/ServiceAlertsView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('Service Alerts'),
    },
  },
  {
    path: '/medications',
    name: 'medications',
    component: () => import(/* webpackChunkName: "medications" */ '@/views/MedicationsView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('Medications'),
    },
  },
  {
    path: '/meds',
    redirect: { name: 'medications' },
  },
  {
    path: '/medications/print',
    name: 'medications-print',
    component: () =>
      import(/* webpackChunkName: "medications-print" */ '@/views/MedicationsPrintView.vue'),
    meta: {
      layout: Layouts.Print,
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('Medication list'),
    },
  },
  {
    path: '/medications/:type/:id',
    name: 'medication-details',
    component: () =>
      import(/* webpackChunkName: "medication-details" */ '@/views/MedicationDetailsView.vue'),
    props: true,
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('Medications'),
    },
  },
  {
    path: '/medications/:type/:id/prescriber',
    name: 'update-prescriber',
    redirect: {
      name: 'update-prescriber-select',
    },
    component: () =>
      import(/* webpackChunkName: "physician-update" */ '@/views/PhysicianUpdateView.vue'),
    meta: {
      layout: Layouts.Blank,
      requireSessionBefore: true,
      requireAuthentication: true,
      title: () => $t('Update Doctor'),
    },
    beforeEnter: (to, from, next) => {
      // users who have not completed onboarding not allowed here
      const currentUser = getCurrentUser()
      if (currentUser && !currentUser.onboarded) {
        return next({ name: 'not-found' })
      }

      if (to.name !== 'update-prescriber-select' && !window.Cypress) {
        // If the user entering add-rx sideways,
        // they should be brought to the beginning of the flow
        return next({ name: 'update-prescriber-select' })
      }
      return next()
    },
    children: [
      {
        path: 'select',
        name: 'update-prescriber-select',
        component: () =>
          import(
            /* webpackChunkName: "update-prescriber" */ '@/views/add-medication/prescriber/UpdatePrescriberSelect.vue'
          ),
        meta: {
          title: () => $t('Update Doctor'),
        },
      },
      {
        path: 'search',
        name: 'update-prescriber-search',
        component: () =>
          import(
            /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberSearch.vue'
          ),
        meta: {
          title: () => $t('Search for your doctor'),
        },
      },
      {
        path: 'results',
        name: 'update-prescriber-results',
        component: () =>
          import(
            /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberResults.vue'
          ),
        props: {
          onSelectRoute: { name: 'update-prescriber-confirm' },
        },
        meta: {
          title: () => $t('Select a Doctor'),
        },
      },
      {
        path: 'refine',
        name: 'update-prescriber-refine',
        component: () =>
          import(
            /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberRefine.vue'
          ),
        meta: {
          title: () => $t('Refine Search'),
        },
      },
      {
        path: 'add-manually',
        name: 'update-prescriber-add-manually',
        component: () =>
          import(
            /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberAddManually.vue'
          ),
        props: ({ query }) => ({
          initialFirstName: query.firstName,
          initialLastName: query.lastName,
          initialPhone: query.phone,
        }),
        meta: {
          title: () => $t('Add a Doctor Manually'),
        },
      },
      {
        path: 'other',
        name: 'update-prescriber-other-meds',
        component: () =>
          import(
            /* webpackChunkName: "update-prescriber" */ '@/views/add-medication/prescriber/UpdatePrescriberOtherMeds.vue'
          ),
        meta: {
          title: () => $t('Other medications'),
        },
      },
      {
        path: 'confirm',
        name: 'update-prescriber-confirm',
        component: () =>
          import(
            /* webpackChunkName: "update-prescriber" */ '@/views/add-medication/prescriber/UpdatePrescriberConfirm.vue'
          ),
        meta: {
          title: () => $t('Confirm your doctor'),
        },
      },
    ],
  },
  {
    path: '/add-medication/',
    name: 'add-medication',
    redirect: {
      name: 'medication-search',
    },
    component: () =>
      import(
        /* webpackChunkName: "add-medication" */ '@/views/add-medication/AddMedicationView.vue'
      ),
    meta: {
      layout: Layouts.Blank,
      requireSessionBefore: true,
      requireAuthentication: true,
      title: () => $t('Add Medication'),
    },
    beforeEnter: (to, from, next) => {
      // users who have not completed onboarding not allowed here
      const currentUser = getCurrentUser()
      if (currentUser && !currentUser.onboarded) {
        return next({ name: 'not-found' })
      }

      if (to.name !== 'medication-search' && !window.Cypress) {
        // If the user entering add-rx sideways,
        // they should be brought to the beginning of the flow
        return next({ name: 'medication-search' })
      }
      return next()
    },
    children: [
      // NOTE: the count & order of these steps is used to determine progress
      {
        path: 'search',
        name: 'medication-search',
        component: () =>
          import(
            /* webpackChunkName: "medication-search" */ '@/views/add-medication/medication/MedicationSearch.vue'
          ),
        meta: {
          isFirstStep: true,
          title: () => $t('Select Medication'),
        },
      },
      {
        path: 'form',
        name: 'medication-form',
        component: () =>
          import(
            /* webpackChunkName: "medication-form" */ '@/views/add-medication/medication/MedicationForm.vue'
          ),
        meta: {
          title: () => $t('Select Medication Form'),
        },
      },
      {
        path: 'strength',
        name: 'medication-strength',
        component: () =>
          import(
            /* webpackChunkName: "medication-strength" */ '@/views/add-medication/medication/MedicationStrength.vue'
          ),
        meta: {
          title: () => $t('Select Medication Strength'),
        },
      },
      {
        path: 'prescriber',
        name: 'prescriber',
        redirect: {
          name: 'prescriber-select',
        },
        component: () =>
          import(
            /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/Prescriber.vue'
          ),
        meta: {
          title: () => $t('Select Doctor'),
        },
        children: [
          {
            path: 'select',
            name: 'prescriber-select',
            component: () =>
              import(
                /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberSelect.vue'
              ),
            meta: {
              title: () => $t('Select a Doctor'),
            },
          },
          {
            path: 'search',
            name: 'prescriber-search',
            component: () =>
              import(
                /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberSearch.vue'
              ),
            meta: {
              title: () => $t('Search for your doctor'),
            },
          },
          {
            path: 'results',
            name: 'prescriber-results',
            component: () =>
              import(
                /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberResults.vue'
              ),
            meta: {
              title: () => $t('Select a Doctor'),
            },
          },
          {
            path: 'refine',
            name: 'prescriber-refine',
            component: () =>
              import(
                /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberRefine.vue'
              ),
            meta: {
              title: () => $t('Refine Search'),
            },
          },
          {
            path: 'add-manually',
            name: 'prescriber-add-manually',
            component: () =>
              import(
                /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberAddManually.vue'
              ),
            props: ({ query }) => ({
              initialFirstName: query.firstName,
              initialLastName: query.lastName,
              initialPhone: query.phone,
            }),
            meta: {
              title: () => $t('Add a Doctor Manually'),
            },
          },
          {
            path: 'confirm',
            name: 'prescriber-confirm',
            component: () =>
              import(
                /* webpackChunkName: "add-prescriber" */ '@/views/add-medication/prescriber/PrescriberConfirm.vue'
              ),
            meta: {
              title: () => $t('Confirm your doctor'),
            },
          },
        ],
      },
      {
        path: 'pharmacy',
        name: 'pharmacy',
        redirect: {
          name: 'pharmacy-previous',
        },
        component: () =>
          import(
            /* webpackChunkName: "add-pharmacy" */ '@/views/add-medication/pharmacy/Pharmacy.vue'
          ),
        meta: {
          title: () => $t('Pharmacy'),
        },
        children: [
          {
            path: 'previous',
            name: 'pharmacy-previous',
            component: () =>
              import(
                /* webpackChunkName: "add-pharmacy" */ '@/views/add-medication/pharmacy/PharmacyPrevious.vue'
              ),
            meta: {
              title: () => $t('Do you have a previous Pharmacy?'),
            },
          },
          {
            path: 'select',
            name: 'pharmacy-select',
            component: () =>
              import(
                /* webpackChunkName: "add-pharmacy" */ '@/views/add-medication/pharmacy/PharmacySelect.vue'
              ),
            meta: {
              title: () => $t('Select a Pharmacy'),
            },
          },
          {
            path: 'search',
            name: 'pharmacy-search',
            component: () =>
              import(
                /* webpackChunkName: "add-pharmacy" */ '@/views/add-medication/pharmacy/PharmacySearch.vue'
              ),
            meta: {
              title: () => $t('Select a Pharmacy'),
            },
          },
          {
            path: 'add-manually',
            name: 'pharmacy-add-manually',
            component: () =>
              import(
                /* webpackChunkName: "add-pharmacy" */ '@/views/add-medication/pharmacy/PharmacyAddManually.vue'
              ),
            meta: {
              title: () => $t('Add a pharmacy manually'),
            },
          },
          {
            path: 'confirm',
            name: 'pharmacy-confirm',
            component: () =>
              import(
                /* webpackChunkName: "add-pharmacy" */ '@/views/add-medication/pharmacy/PharmacyConfirm.vue'
              ),
            meta: {
              title: () => $t('Confirm your pharmacy'),
            },
          },
        ],
      },
      {
        path: 'delivery',
        name: 'delivery',
        component: () =>
          import(/* webpackChunkName: "delivery" */ '@/views/add-medication/delivery/Delivery.vue'),
        meta: {
          title: () => $t('Delivery'),
        },
      },
      {
        path: 'review',
        name: 'review',
        component: () =>
          import(/* webpackChunkName: "review" */ '@/views/add-medication/review/Review.vue'),
        meta: {
          title: () => $t('Review your medication request'),
        },
      },
    ],
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/OrdersView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('Orders'),
    },
  },
  {
    path: '/billing',
    redirect: { name: 'orders' },
  },
  {
    path: '/orders/:id',
    name: 'order-details',
    props: route => ({ id: route.params.id }),
    component: () => import(/* webpackChunkName: "order-details" */ '@/views/OrderDetailsView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('Orders'),
    },
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '@/views/EventsView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('Events'),
    },
  },
  {
    path: '/verify',
    name: 'verify',
    component: MultiFAView,
    meta: {
      requireSession: true,
      requireAuthentication: false,
      layout: Layouts.NoNavbar,
      title: () => $t('Verify Code'),
    },

    beforeEnter: async (to, from, next) => {
      const mfaEnabled = await FeatureFlags.enabled('mfa-enabled')

      if (mfaEnabled) {
        if (Vue.$pillpack.isMFAAuthenticated) {
          next('/')
        } else if (!Vue.$pillpack.isLoggedIn) {
          next('/login')
        } else {
          next()
        }

        return
      }

      if (Vue.$pillpack.isLoggedIn) {
        next('/')
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/locked',
    name: 'locked',
    component: AccountLockedView,
    meta: {
      requireSession: false,
      requireAuthentication: false,
      layout: Layouts.NoNavbar,
      title: () => $t('Account Locked'),
    },
    beforeEnter: async (to, from, next) => {
      const mfaEnabled = await FeatureFlags.enabled('mfa-enabled')

      if (!mfaEnabled) {
        next('/')
      } else {
        next()
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requireSessionBefore: true,
      layout: Layouts.NoNavbar,
      title: () => $t('Sign in'),
    },
    props: route => ({ redirectReason: route.query.redirectReason }),

    beforeEnter: async (to, from, next) => {
      const mfaEnabled = await FeatureFlags.enabled('mfa-enabled')

      if (mfaEnabled) {
        if (Vue.$pillpack.isMFAAuthenticated) {
          next('/')
        } else if (Vue.$pillpack.isLoggedIn) {
          next('/verify')
        } else {
          next()
        }

        return
      }

      if (Vue.$pillpack.isLoggedIn) {
        next('/')
      }
      next()
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    meta: {
      requireSessionBefore: true,
      layout: Layouts.NoNavbar,
      title: () => $t('Signing out'),
    },
    beforeEnter: (to, from, next) => {
      if (!Vue.$pillpack.isLoggedIn) {
        next('/login')
      }
      next()
    },
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordResetView,
    meta: {
      requireSessionBefore: true,
      layout: Layouts.NoNavbar,
      title: () => $t('Reset password'),
    },
    props: route => ({ redirectReason: route.query.redirectReason }),

    beforeEnter: (to, from, next) => {
      if (Vue.$pillpack.isLoggedIn) {
        next('/')
      }
      next()
    },
  },
  {
    path: '/password-edit',
    name: 'password-edit',
    component: () => import(/* webpackChunkName: "password-edit" */ '@/views/PasswordEditView.vue'),
    props: route => ({ token: route.query.reset_password_token }),
    meta: {
      requireSessionBefore: true,
      layout: Layouts.NoNavbar,
      title: () => $t('Edit password'),
    },
    beforeEnter: (to, from, next) => {
      if (Vue.$pillpack.isLoggedIn) {
        next('/')
      }
      next()
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/SettingsView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('pages.settings.title'),
    },
  },
  {
    path: '/account',
    redirect: { name: 'settings' },
  },
  {
    path: '/settings/contact',
    name: 'contact-preferences',
    component: () =>
      import(
        /* webpackChunkName: "contact-preferences" */ '@/views/settings/ContactPreferencesView.vue'
      ),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('pages.settings.contact.title'),
    },
  },
  {
    path: '/settings/health',
    name: 'health-profile',
    component: () =>
      import(/* webpackChunkName: "health-profile" */ '@/views/settings/HealthProfileView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('pages.settings.health.title'),
    },
  },
  {
    path: '/settings/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/settings/PaymentView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('pages.settings.payment.title'),
    },
  },
  {
    path: '/settings/goPaperless',
    name: 'goPaperless',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/settings/GoPaperless.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('pages.settings.goPaperless.title'),
    },
  },
  {
    path: '/settings/addresses',
    name: 'addresses',
    component: () =>
      import(/* webpackChunkName: "addresses" */ '@/views/settings/AddressesView.vue'),
    meta: {
      requireSession: true,
      requireAuthentication: true,
      title: () => $t('pages.settings.shipping.title'),
    },
  },
  {
    path: '/help',
    name: 'help',
    beforeEnter: async (to, from, next) => {
      if (await FeatureFlags.enabled('amazon-chat')) {
        window.location.href =
          'https://www.amazon.com/gp/help/customer/display.html?nodeId=TrVbZEq7REjZm5P7N3'
      } else {
        next()
      }
    },
    component: HelpView,
    meta: {
      worksOffline: true,
      title: () => $t('Help'),
    },
  },
  {
    path: '/version',
    name: 'version',
    component: () => import(/* webpackChunkName: "version" */ '@/views/Version.vue'),
    meta: {
      worksOffline: true,
      title: () => $t('Version'),
    },
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFoundView,
    meta: {
      worksOffline: true,
      title: () => $t('Page not found'),
    },
  },
]

export default routes
