


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import User from '@/models/User'
import { BannerInfo } from '@/vuex/banners'

@Component({
  components: { Modal, AlertMessage },
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
    ...mapState('user', ['me']),
  },
})
export default class EditGoPaperlessModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  me!: User
  goPaperless: boolean = false

  onShow() {
    this.goPaperless = this.me?.preferences?.goPaperless === 'on'
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.$store.commit('asyncStatus/reset', { key: 'user/updateGoPaperless' })
  }

  async updateSetting() {
    const turningOn = !this.goPaperless

    await this.$store.dispatch('user/updateGoPaperless', {
      enable: turningOn ? 'on' : 'off',
    })

    if (this.hasSucceeded('user/updateGoPaperless')) {
      const banner: BannerInfo = {
        id: 'updateGoPaperlessBanner',
        message: turningOn
          ? this.$t('Avoid printed medication paperwork when applicable')
          : this.$t('Receive printed medication paperwork when applicable'),
        title: this.$t('Preference changed'),
        bgVariant: turningOn ? 'success' : 'danger',
        durationMs: 5000,
      }

      this.$store.dispatch('showBanner', banner)
      this.close()
    }
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }
}
