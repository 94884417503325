





















import { Component, Vue } from 'vue-property-decorator'

import IconSpeakerphone from '@/assets/icons/icon-speakerphone.svg'
import IconHealth from '@/assets/icons/icon-health.svg'
import IconInsuranceCard from '@/assets/icons/icon-insurance-card.svg'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import IconHouse from '@/assets/icons/icon-house.svg'
import Card from '@/components/Card.vue'
import featureFlags from '@/util/featureFlags'
import IconBlank from '@/assets/icons/icon-blank.svg'

@Component({
  components: {
    Card,
    LinkArrowIcon,
  },
})
export default class SettingsNavCard extends Vue {
  showPaperlessShipmentLiterature: boolean = false

  get links() {
    const linksTemp = [
      {
        routeName: 'contact-preferences', // name must match router name
        title: this.$t('pages.settings.contact.title'),
        subtitle: this.$t('pages.settings.contact.subtitle'),
        to: { name: 'contact-preferences' },
        icon: IconSpeakerphone,
      },
      {
        routeName: 'health-profile',
        title: this.$t('pages.settings.health.title'),
        subtitle: this.$t('pages.settings.health.subtitle'),
        to: { name: 'health-profile' },
        icon: IconHealth,
      },
      {
        routeName: 'shipping-address',
        title: this.$t('pages.settings.shipping.title'),
        subtitle: this.$t('pages.settings.shipping.subtitle'),
        to: { name: 'addresses' },
        icon: IconHouse,
      },
      {
        routeName: 'payment',
        title: this.$t('pages.settings.payment.title'),
        subtitle: this.$t('pages.settings.payment.subtitle'),
        to: { name: 'payment' },
        icon: IconInsuranceCard,
      },
    ]
    if (this.showPaperlessShipmentLiterature) {
      linksTemp.push({
        routeName: 'goPaperless',
        title: this.$t('pages.settings.goPaperless.title'),
        subtitle: this.$t('pages.settings.goPaperless.subtitle'),
        to: { name: 'goPaperless' },
        icon: IconBlank,
      })
    }
    return linksTemp
  }

  async created(): Promise<void> {
    if (await featureFlags.enabled('enable-go-paperless')) {
      this.showPaperlessShipmentLiterature = true
    }
  }
}
