




































import { mapState, mapGetters } from 'vuex'
import { Component, Vue } from 'vue-property-decorator'

import { PPError } from '@/ppapi/PPError'
import DashboardMedication from '@/models/DashboardMedication'

import PageHeader from '@/components/PageHeader.vue'
import AddMedDropdown from '@/components/medications/AddMedDropdown.vue'
import AddMedPrescription from '@/components/medications/AddMedPrescription.vue'
import AddMedOverTheCounter from '@/components/medications/AddMedOverTheCounter.vue'
import ActiveMedicationsCard from '@/components/medications/ActiveMedicationsCard.vue'
import PausedMedicationsCard from '@/components/medications/PausedMedicationsCard.vue'
import PendingMedicationsCard from '@/components/medications/PendingMedicationsCard.vue'
import MedicationListActionsCard from '@/components/medications/MedicationListActionsCard.vue'
import PastMedicationsCard from '@/components/medications/PastMedicationsCard.vue'
import featureFlags from '@/util/featureFlags'

@Component({
  components: {
    PageHeader,
    AddMedDropdown,
    AddMedPrescription,
    AddMedOverTheCounter,
    ActiveMedicationsCard,
    PausedMedicationsCard,
    PendingMedicationsCard,
    MedicationListActionsCard,
    PastMedicationsCard,
  },
  computed: {
    ...mapState('medications', ['sortBy']),
    ...mapGetters('medications', [
      'sortedActiveMedications',
      'sortedPausedMedications',
      'sortedPastMedications',
      'sortedPendingMedications',
    ]),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
})
export default class MedicationsView extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  sortedActiveMedications!: DashboardMedication[]
  sortedPausedMedications!: DashboardMedication[]
  sortedPastMedications!: DashboardMedication[]
  sortedPendingMedications!: DashboardMedication[]
  showPastMedications!: boolean
  showSplitButtons: boolean = false

  async created(): Promise<void> {
    this.$store.dispatch('medications/load')
    this.$store.dispatch('medications/loadPending')
    this.showSplitButtons = await featureFlags.enabled('jdi-cc-split-add-med-button')
  }

  get showActiveMedications() {
    return this.sortedActiveMedications.length || this.isInProgress('medications/load')
  }

  get showPausedMedications() {
    return this.sortedPausedMedications.length || this.isInProgress('medications/load')
  }

  get hasPendingMedications() {
    return this.sortedPendingMedications.length
  }

  get hasPastMedications() {
    return this.sortedPastMedications.length
  }

  get displayMissingMsg() {
    return (
      this.getError('medications/load') ||
      (this.hasSucceeded('medications/load') &&
        !this.sortedActiveMedications.length &&
        !this.sortedPastMedications.length &&
        this.hasSucceeded('medications/loadPending') &&
        !this.sortedPendingMedications.length)
    )
  }
}
