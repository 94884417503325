

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Modal from '@/components/Modal.vue'
import ChatLink from '@/components/ChatLink.vue'
import User from '@/models/User'
import Prescription from '@/models/Prescription'
import amazonZendeskUrlFF from '@/util/amazonZendeskUrlFF'

@Component({
  components: { Modal, ChatLink },
  computed: {
    ...mapState('user', ['me']),
  },
})
export default class MedPreferencesInfoModal extends Vue {
  amazonZendeskUrlFF = amazonZendeskUrlFF
  @Prop({ type: Object, required: true })
  prescription!: Prescription

  me!: User

  get ninetyDayAvailable() {
    return (
      this.me.usesMedicationFillPreferences &&
      this.prescription.dispenseDaysSupply === 30 &&
      !this.prescription.controlled
    )
  }
}
