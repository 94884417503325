import Vue from 'vue'
import Router, { NavigationGuard } from 'vue-router'
import eventBus from '@/util/EventBus'
import { trackPageView } from '@/plugins/GoogleAnalyticsPlugin'
import store from '@/store'
import getCurrentUser from '@/util/getCurrentUser'
import routes from '@/routes'
import FeatureFlags from '@/util/featureFlags'
import getApiHost from '@/util/apiHost'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

const fetchUserOnTransition: NavigationGuard = async (to, from, next) => {
  const requireSessionBefore = to.matched.some(route => route.meta.requireSessionBefore)
  const requireAuthentication = to.matched.some(route => route.meta.requireAuthentication)
  const caregiverRoute = to.matched.some(route => route.name === 'caregiver')
  const featureFlag = to.matched[0].meta.featureFlag

  // Call next() before fetching the session if it is not needed for other route guards
  if (!requireSessionBefore && !featureFlag) next()
  if (!Vue.$pillpack.hasSession) {
    await store.dispatch('startSession')
  } else {
    await Promise.resolve()
  }

  let isFullyAuthenticated = Vue.$pillpack.isLoggedIn

  const mfaEnabled = await FeatureFlags.enabled('mfa-enabled')
  if (mfaEnabled) {
    isFullyAuthenticated = Vue.$pillpack.isMFAAuthenticated
  }

  // Check if route is behind feature flag
  if (featureFlag) {
    const enabled = await FeatureFlags.enabled(featureFlag)
    if (!enabled) {
      next({ name: 'not-found' })
      return
    }
  } else {
    await Promise.resolve()
  }

  // Call next() after fetching the session if it is needed for other route guards
  if (requireSessionBefore || featureFlag) next()

  // If `requiredAuthenticated` is set, this means that the route requires that the user
  // is both logged in and, when the MFA FF is eanbled, has authenticated via MFA
  if (requireAuthentication && !isFullyAuthenticated) {
    router.push({
      path: '/login',
      query: { redirect_url: to.path },
    })
    return
  }

  // Ensure the user is loaded for the entire app
  if (isFullyAuthenticated) {
    await store.dispatch('user/ensureMe')
  }

  const currentUser = getCurrentUser()

  // If the user is a caregiver but not signed up,
  // redirect to the caregiver page.
  if (
    requireAuthentication &&
    !caregiverRoute &&
    currentUser &&
    !currentUser.signedUp &&
    currentUser.isCareGiver
  ) {
    router.push({
      path: '/caregiver',
    })
  }

  // If the user is not a caregiver and not signed up,
  // redirect to signup
  if (requireAuthentication && !currentUser?.isCareGiver && !currentUser?.signedUp) {
    window.$location.replace(`${getApiHost()}/signup`)
  }

  if (requireAuthentication && currentUser?.inAmazonPharmacy) {
    router.push('/not-found')
  }
}

router.beforeEach(fetchUserOnTransition)

export const handleCaregiverTransition: NavigationGuard = (to, from, next) => {
  if (to.query.caringFor) {
    // Assume the carereceivers id if it is not already set
    if (!Vue.$pillpack.isAssumedUser) {
      Vue.$pillpack.assumeUser(to.query.caringFor as string)
    }
    next()
  } else if (to.name === 'caregiver') {
    // sign out of the carereceiver if this route is navigated
    // to without a caringFor id
    if (Vue.$pillpack.isAssumedUser) {
      Vue.$pillpack.clearAssumedUser()
    }
    next()
  } else if (to.name === 'login' || to.name === 'logout') {
    next()
  } else if (from.query.caringFor && !to.query.caringFor) {
    // Carry the caringFor query param through normal navigation
    next({ path: to.path, query: { caringFor: from.query.caringFor } })
  } else {
    next()
  }
}

router.beforeEach(handleCaregiverTransition)

export const handlePageTitle: NavigationGuard = (to, from, next) => {
  const hasTitle = to.meta.title && typeof to.meta.title === 'function'
  if (hasTitle) {
    store.commit('setPageTitle', to.meta.title())
  } else {
    store.commit('setPageTitle', '')
  }
  next()
}

router.beforeEach(handlePageTitle)

router.afterEach(to => {
  trackPageView(to.path)
})

eventBus.addEventListener('PPClient.SessionReset', () => {
  router.replace({
    path: '/login',
    query: { redirect_url: router.currentRoute.path, redirectReason: 'sessionTimeout' },
  })
})

eventBus.addEventListener('SessionMonitor.SessionExpiration', () => {
  router.push({ name: 'logout' })
})

export default router
