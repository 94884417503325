import { render, staticRenderFns } from "./AddMedPrescription.vue?vue&type=template&id=2132fab5&scoped=true&"
import script from "./AddMedPrescription.vue?vue&type=script&lang=ts&"
export * from "./AddMedPrescription.vue?vue&type=script&lang=ts&"
import style0 from "./AddMedPrescription.vue?vue&type=style&index=0&id=2132fab5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2132fab5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import IconAdd from '@/assets/icons/icon-add.svg'
installComponents(component, {BButton,IconAdd})
