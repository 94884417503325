import { format } from 'date-fns'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { serialize, deserialize, deserializeArray, serializeArray } from '@/models/transforms'
import DashboardMedication, { MedicationType } from '@/models/DashboardMedication'
import MedicationLiterature from '@/models/MedicationLiterature'
import OrderRushShipmentResponse from '@/models/OrderRushShipmentResponse'
import RushShipmentOption from '@/models/RushShipmentOption'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import { TransferInRequest, NewMedRequest } from '@/models/NewMedRequest'
import { PPArgs, PPBaseArgs } from './PPClient'
import { formatDate } from '@/i18n'
import Today from '@/models/Today'
import RxCatalogDrugItem from '@/models/RxCatalogDrugItem'
import RxCatalogConcept from '@/models/RxCatalogConcept'
import SigLine from '@/models/PrescriptionSigLine'
import featureFlags from '@/util/featureFlags'

// used to fetch both SignupMeds and New Meds for PPCore
const PENDING_MED_URI = 'pending'

export interface MedicationResponse {
  activeMedications?: DashboardMedication[]
  pastMedications?: DashboardMedication[]
  pendingMedications?: DashboardMedication[]
}

export interface MedChangeRequestParams {
  prescriptionId: string
  daysSupply: number
  inPackets: boolean
  hoaPreferences: SigLine[]
  outreachConsent: boolean
}

export interface PhysicianUpdateParams {
  prescriptionId: string
  physicianId: string
  otherPrescriptionIds?: string[] // Used for updating multiple prescriptions at once
  location?: string
}

export default class PPMedicationsApi {
  static async get(args: PPArgs, pending: boolean): Promise<PPResponse<MedicationResponse>> {
    const path = pending ? `dashboard_medications/${PENDING_MED_URI}` : 'dashboard_medications'

    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/${path}`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    // user_medication endpoints can return some or all of these medication types
    const [activeMedications, pastMedications, pendingMedications] = [
      'active_medications',
      'past_medications',
      'pending_medications',
    ].map(prop =>
      response.data[prop] ? deserializeArray(DashboardMedication, response.data[prop]) : undefined,
    )

    return new PPSuccessResponse({ activeMedications, pastMedications, pendingMedications })
  }

  static async getToday(args: PPArgs): Promise<PPResponse<Today>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/today`,
      urlParams: {
        date: formatDate(new Date(), 'YYYY-MM-DD'),
        locale: args.locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const today = deserialize(Today, response.data)

    return new PPSuccessResponse(today)
  }

  static async getMed(
    args: PPArgs,
    { medId, type }: { medId: string; type: MedicationType },
  ): Promise<PPResponse<DashboardMedication>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/dashboard_medications/${type}/${medId}`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const medication = deserialize(DashboardMedication, response.data)

    return new PPSuccessResponse(medication)
  }

  static async pauseAutoRefill(args: PPArgs, rxId: string): Promise<PPResponse<boolean>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.DELETE,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/prescriptions/${rxId}/auto_refill`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return new PPSuccessResponse(true)
  }

  static async resumeAutoRefill(args: PPArgs, rxId: string): Promise<PPResponse<boolean>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/prescriptions/${rxId}/auto_refill`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return new PPSuccessResponse(true)
  }

  static async literature(
    args: PPBaseArgs,
    ndc9: string,
  ): Promise<PPResponse<MedicationLiterature>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/medication/literature/${ndc9}`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const literature = deserialize(MedicationLiterature, response.data)

    return new PPSuccessResponse(literature)
  }

  static async delete(args: PPArgs, rxId: string): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.DELETE,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/prescriptions/${rxId}`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return PPSuccessResponse.voidResponse()
  }

  static async requestRefill(args: PPArgs, rxId: string): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/prescriptions/${rxId}/refill_request`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return PPSuccessResponse.voidResponse()
  }

  static async cancelRefillRequest(args: PPArgs, rxId: string): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.DELETE,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/prescriptions/${rxId}/refill_request`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return PPSuccessResponse.voidResponse()
  }

  static async medPreferenceChangeRequest(
    args: PPArgs,
    {
      prescriptionId,
      daysSupply,
      inPackets,
      hoaPreferences,
      outreachConsent,
    }: MedChangeRequestParams,
  ): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/prescriptions/${prescriptionId}/medication_preferences`,
      urlParams: { locale: args.locale },
      content: {
        days_supply: daysSupply,
        in_packets: inPackets,
        hoa_preferences: serializeArray(hoaPreferences || []),
        outreach_consent: outreachConsent,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return PPSuccessResponse.voidResponse()
  }

  static async physicianUpdateRequest(
    args: PPArgs,
    { prescriptionId, physicianId, otherPrescriptionIds, location }: PhysicianUpdateParams,
  ): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/prescriptions/${prescriptionId}/physician`,
      urlParams: { locale: args.locale },
      content: {
        physician_id: physicianId,
        other_prescription_ids: otherPrescriptionIds,
        search_location: location,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return PPSuccessResponse.voidResponse()
  }

  static async orderRushShipment(
    args: PPArgs,
    { rxId, dateNeeded }: { rxId: string; dateNeeded: Date },
  ): Promise<PPResponse<Date>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/prescriptions/${rxId}/rush_shipment`,
      urlParams: { locale: args.locale },
      content: {
        date: format(dateNeeded, 'YYYY-MM-DD'),
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const data = deserialize(OrderRushShipmentResponse, response.data)

    return new PPSuccessResponse(data.rushShipmentDate)
  }

  static async cancelRushShipment(args: PPArgs, rxId: string): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.DELETE,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/prescriptions/${rxId}/rush_shipment`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return new PPSuccessResponse(undefined)
  }

  static async getRushShipmentOptions(
    args: PPArgs,
    rxId: string,
  ): Promise<PPResponse<RushShipmentOption[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/prescriptions/${rxId}/rush_shipment/new`,
      urlParams: { locale: args.locale },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserializeArray(
      RushShipmentOption,
      response.data.rush_shipment_options.arrival_dates,
    )

    return new PPSuccessResponse(results)
  }

  static async getRxConcepts(
    args: PPBaseArgs,
    { name, userId }: { name: string; userId: string },
  ): Promise<PPResponse<RxCatalogConcept[]>> {
    const isLtboFeatureFlagEnabled = await featureFlags.enabled('ltbo-pillpack')

    let apiPath
    if (isLtboFeatureFlagEnabled) {
      apiPath = `/api/v2/rx_catalog/${name}/${userId}/concepts`
    } else {
      apiPath = `/api/v2/rx_catalog/${name}/concepts`
    }
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: apiPath,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserializeArray(RxCatalogConcept, response.data)

    return new PPSuccessResponse(results)
  }

  static async getRxCatalog(args: PPBaseArgs): Promise<PPResponse<RxCatalogDrugItem[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: '/api/v2/rx_catalog',
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return new PPSuccessResponse(response.data)
  }

  static async search(
    args: PPBaseArgs,
    { query }: { query: string },
  ): Promise<PPResponse<string[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: '/api/good_rx/drug_search',
      urlParams: { query },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = response.data.data.candidates.map((name: string) => name.toUpperCase())

    return new PPSuccessResponse(results)
  }

  static async strengthInfo(
    args: PPBaseArgs,
    { name }: { name: string },
  ): Promise<PPResponse<PrescriptionStrength[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: '/api/good_rx/drug_info',
      urlParams: { name },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserializeArray(PrescriptionStrength, response.data)

    return new PPSuccessResponse(results)
  }

  static async addNewRXRequest(
    args: PPArgs,
    newMedRequest: NewMedRequest,
  ): Promise<PPResponse<any>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/prescriptions/new_prescription_request`,
      urlParams: { locale: args.locale },
      content: serialize(NewMedRequest, newMedRequest),
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const data = response.data

    return new PPSuccessResponse(data)
  }

  static async transferInRequest(
    args: PPArgs,
    transferInRequest: TransferInRequest,
  ): Promise<PPResponse<any>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/prescriptions/transfer_in_request`,
      urlParams: { locale: args.locale },
      content: serialize(TransferInRequest, transferInRequest),
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const data = response.data

    return new PPSuccessResponse(data)
  }
}
