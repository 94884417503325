import { render, staticRenderFns } from "./MultiFA.vue?vue&type=template&id=47f84fb6&scoped=true&"
import script from "./MultiFA.vue?vue&type=script&lang=ts&"
export * from "./MultiFA.vue?vue&type=script&lang=ts&"
import style0 from "./MultiFA.vue?vue&type=style&index=0&id=47f84fb6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f84fb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BAlert } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BFormInput } from 'bootstrap-vue'
import { BLink } from 'bootstrap-vue'
installComponents(component, {BAlert,BForm,BFormGroup,BFormInput,BLink})
