import Deferred from '@/util/deferred'

const FLAG_PREFIX = 'consumer-web-app-'

export interface FeatureFlag {
  name: string
  enabled: boolean
  options?: any
}

class FeatureFlags {
  flags = new Map()
  private initialized = false
  private initializer = new Deferred()

  async set(flags: FeatureFlag[]) {
    flags.forEach(flag => {
      const name = flag.name.replace(FLAG_PREFIX, '')
      this.flags.set(name, { ...flag, name })
    })
    await this.initializer.resolve()
    this.initialized = true
  }

  async get(name: string) {
    if (!this.initialized) {
      await this.initializer.promise
    }
    return this.flags.get(name)
  }

  async enabled(name: string) {
    if (!this.initialized) {
      await this.initializer.promise
    }
    return this.flags.get(name)?.enabled
  }

  async has(name: string) {
    if (!this.initialized) {
      await this.initializer.promise
    }
    return this.flags.has(name)
  }

  async ensureInitialized() {
    if (!this.initialized) {
      await this.initializer.promise
    }
  }
}

const featureFlags = new FeatureFlags()

;(window as any).featureFlags = featureFlags

export default featureFlags
