





















import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { Route } from 'vue-router'
import { injectStripeScript } from '@/util/stripe'
import sleep from '@/util/sleep'
import Loading from '@/components/Loading.vue'
import SessionErrorElement from '@/components/SessionErrorElement.vue'
import OfflineElement from '@/components/OfflineElement.vue'
import ErrorModal from '@/components/ErrorModal.vue'
import User from '@/models/User'
import TrustDeviceModal from '@/components/TrustDeviceModal.vue'

import Blank from '@/layouts/Blank.vue'
import Default from '@/layouts/Default.vue'
import NoNavbar from '@/layouts/NoNavbar.vue'
import Print from '@/layouts/Print.vue'

const SCRIPT_INJECT_DELAY = 5000

@Component({
  components: {
    // Layouts
    Blank,
    Default,
    NoNavbar,
    Print,
    // Components
    Loading,
    SessionErrorElement,
    OfflineElement,
    ErrorModal,
    TrustDeviceModal,
    AddUserIdentificationModal: () =>
      import(
        /* webpackChunkName: "user-id-modal" */ '@/components/settings/AddUserIdentificationModal.vue'
      ),
    InstallPWAModal: () =>
      import(/* webpackChunkName: "install-pwa-modal" */ '@/components/InstallPWAModal.vue'),
    SessionExpirationModal: () =>
      import(
        /* webpackChunkName: "session-expiration-modal" */ '@/components/SessionExpirationModal.vue'
      ),
    PayerWelcomeModal: () =>
      import(/* webpackChunkName: "payer-welcome-modal" */ '@/components/PayerWelcomeModal.vue'),
  },
  computed: {
    ...mapState(['isOffline', 'locale', 'showSessionExpirationModal']),
    ...mapState('welcome', ['payerWelcomeModal']),
    ...mapState('layout', ['menuHeight', 'isMenuFixed']),
    ...mapState('user', ['userIdentificationModal', 'me']),
    ...mapState('pwa', ['loadPWAPrompts']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
    ...mapGetters(['isLoggedIn']),
  },
})
export default class App extends Vue {
  locale!: string
  menuHeight!: number
  isMenuFixed!: boolean
  isOffline!: boolean
  isLoggedIn!: boolean
  loadPWAPrompts!: boolean
  showPWAModal!: boolean
  payerWelcomeModal!: boolean
  showSessionExpirationModal!: boolean
  userIdentificationModal!: { visible: boolean }
  me!: User
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => Error

  get layout() {
    if (this.isOffline || this.isDisabled) return 'NoNavbar'

    const routeWithLayout = this.$route.matched.find(route => route.meta.layout)
    return routeWithLayout ? routeWithLayout.meta.layout : 'Default'
  }

  get showOffline() {
    return !this.routeWorksOffline && this.isOffline
  }

  get isDisabled() {
    return this.me.inAmazonPharmacy
  }

  get isFetchingSession() {
    return this.pageRequiresSession && this.isInProgress('startSession')
  }

  get failedToFetchSession() {
    return this.pageRequiresSession && !!this.getError('startSession')
  }

  get routeWorksOffline() {
    return this.$route.meta.worksOffline
  }

  get pageRequiresSession() {
    return this.$route.meta.requireSession
  }

  get mockHeight() {
    if (this.isMenuFixed && !this.$route.meta.hideNav) {
      return `${this.menuHeight}px`
    } else {
      return '0'
    }
  }

  get isNotLogin() {
    return this.$route.name !== 'login'
  }

  async mounted() {
    this.$store.dispatch('updateOnlineStatus')

    // let's wait a while to inject the stripe scripts
    // both of them cause significant stall when loading the app
    await sleep(SCRIPT_INJECT_DELAY)
    injectStripeScript()
  }

  @Watch('$route')
  onRouteChange(to: Route) {
    // Accessible routing adapted from:
    // https://codesandbox.io/s/improved-accessible-routing-vue-u0dh9
    setTimeout(() => {
      if (to.meta.title) {
        const title = to.meta.title()
        this.setRouteAnnouncement(title.toLocaleLowerCase())
      }
      if (!to.hash) {
        // If the hash is set, the browser will focus for us
        this.setRouteMainElFocus()
      }
    }, 1)
  }

  setRouteMainElFocus() {
    const activeElement = document.activeElement
    // If the previous active element is still on the page, don't change the focus
    // (i.e. Navbar links)
    if (activeElement && activeElement !== document.body) return
    const el = this.$el as HTMLElement
    if (!el) return
    el.focus()
  }

  setRouteAnnouncement(title: string) {
    this.$announcer.set(title)
  }
}
