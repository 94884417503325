import Vue from 'vue'
import { Module } from 'vuex'
import User from '@/models/User'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import CareRelationship, { CaregiverStatus } from '@/models/CareRelationship'
import { BannerInfo } from '@/vuex/banners'
import { $t } from '@/i18n'
import OnboardState from '@/models/Onboard/OnboardState'
import tel, { PILLPACK_PHONE } from '@/util/phoneNumber'
import openZendesk from '@/util/zendesk'
import getApiHost from '@/util/apiHost'

export interface UserIdentificationModalInfo {
  visible: boolean
  onComplete?: Function | null
}

export interface UserState {
  me: User
  caregiver: User
  onboardState: OnboardState | null
  userIdentificationModal: UserIdentificationModalInfo
}

const state: UserState = {
  me: new User(),
  caregiver: new User(),
  onboardState: null,
  userIdentificationModal: {
    visible: false,
    onComplete: null,
  },
}

const module: Module<UserState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    updateUser(state, user: User): void {
      state.me = user
    },

    updateCaregiver(state, user: User): void {
      state.caregiver = user
    },

    updateOnboardState(state, obj: OnboardState): void {
      state.onboardState = obj
    },

    addCaregiver(state, caregiver: CareRelationship) {
      if (!state.me.caregivers) {
        state.me.caregivers = []
      }

      state.me.caregivers.push(caregiver)
    },

    removeCaregiver(state, id: string) {
      if (!state.me.caregivers) return

      state.me.caregivers = state.me.caregivers.filter(care => care.id !== id)
    },

    resetState(state) {
      state.me = new User()
      state.caregiver = new User()
      state.onboardState = null
    },

    setUserIdentificationModal(state, { visible, onComplete }: UserIdentificationModalInfo) {
      state.userIdentificationModal.visible = visible
      state.userIdentificationModal.onComplete = onComplete || null
    },

    setAutopay(state, { enabled }) {
      if (!state.me.account) return

      state.me.account.autopay = enabled
    },

    setAccount(state, { account }) {
      if (!state.me) return

      state.me.account = account
    },
  },

  actions: {
    loadMe: trackAsyncStatus('user/loadMe', async function ({ commit }) {
      const user = await Vue.$pillpack.users.me()
      if (Vue.$pillpack.isAssumedUser) {
        user.isAssumedUser = true
      }
      commit('updateUser', user)
    }),

    loadCaregiver: trackAsyncStatus('user/loadCaregiver', async function ({ commit }) {
      const authenticatedUserId = Vue.$pillpack.authenticatedUserId
      if (!authenticatedUserId) return

      const user = await Vue.$pillpack.users.user(authenticatedUserId)
      commit('updateCaregiver', user)
    }),

    loadOnboardState: trackAsyncStatus('user/loadOnboardState', async function ({ commit }) {
      const state = await Vue.$pillpack.users.onboardState()
      commit('updateOnboardState', state)
    }),

    ensureMe: async ({ state, dispatch }) => {
      if (state.me.id) {
        return
      }
      await dispatch('loadMe')
    },

    addCaregiver: trackAsyncStatus(
      'user/addCaregiver',
      async function ({ commit }, caregiverEmail) {
        const caregiverRelationship = await Vue.$pillpack.users.addCaregiver(caregiverEmail)
        commit('addCaregiver', caregiverRelationship)
      },
    ),

    removeCaregiver: trackAsyncStatus(
      'user/removeCaregiver',
      async function ({ commit }, caregiverId) {
        await Vue.$pillpack.users.removeCaregiver(caregiverId)
        commit('removeCaregiver', caregiverId)
      },
    ),

    updateName: trackAsyncStatus(
      'user/updateName',
      async function ({ commit }, { name, nameProperty }: { name: string; nameProperty: string }) {
        const payload: any = {}
        payload[nameProperty] = name

        const user = await Vue.$pillpack.users.updateUser(payload)
        commit('updateUser', user)
      },
    ),

    resendEmailConfirmation: trackAsyncStatus('user/resendEmailConfirmation', async () => {
      await Vue.$pillpack.users.resendEmailConfirmation()
    }),

    updateEmail: trackAsyncStatus('user/updateEmail', async function ({ commit }, email) {
      const user = await Vue.$pillpack.users.updateUser({ email })
      commit('updateUser', user)
    }),

    updatePassword: trackAsyncStatus('user/updatePassword', async function (_context, password) {
      await Vue.$pillpack.users.updatePassword(password)
    }),

    updatePhoneNumber: trackAsyncStatus(
      'user/updatePhoneNumber',
      async function ({ commit }, phoneNumber) {
        const user = await Vue.$pillpack.users.updateUser({ phoneNumber })
        commit('updateUser', user)
      },
    ),

    updateSms: trackAsyncStatus(
      'user/updateSms',
      async function ({ commit }, { enable, phoneNumber }) {
        const user = await Vue.$pillpack.users.updateSmsPreference({ enable, phoneNumber })
        commit('updateUser', user)
      },
    ),

    updatePhiInCommunications: trackAsyncStatus(
      'user/updatePhiInCommunications',
      async function ({ commit }, { enable }) {
        const user = await Vue.$pillpack.users.updatePhiInCommunicationsPreference(enable)
        commit('updateUser', user)
      },
    ),

    updateChildSafePackaging: trackAsyncStatus(
      'user/updateChildSafePackaging',
      async function ({ commit }, { enable }) {
        const user = await Vue.$pillpack.users.updateChildSafePackaging(enable)
        commit('updateUser', user)
      },
    ),

    updateGoPaperless: trackAsyncStatus(
      'user/updateGoPaperless',
      async function ({ commit }, { enable }) {
        const user = await Vue.$pillpack.users.updateGoPaperless(enable)
        commit('updateUser', user)
      },
    ),

    updateUserIdentification: trackAsyncStatus(
      'user/updateUserIdentification',
      async function ({ commit }, payload) {
        const user = await Vue.$pillpack.users.updateUserIdentification(payload)
        commit('updateUser', user)
      },
    ),

    updateLastFour: trackAsyncStatus(
      'user/updateLastFour',
      async function ({ commit }, { ssn }: { ssn: string }) {
        const user = await Vue.$pillpack.users.updateUser({ ssn })
        commit('updateUser', user)
      },
    ),

    setUserIdentificationModal({ commit }, { visible, onComplete }: UserIdentificationModalInfo) {
      commit('setUserIdentificationModal', { visible, onComplete })
    },

    checkIfRequiresIdentification({ state, dispatch }) {
      if (!state.me.requireIdentification || state.me.hasIdentification) return null
      const banner: BannerInfo = {
        id: 'user-requires-id',
        bgVariant: 'danger',
        title: $t('Action needed:'),
        message: $t('addresses.addressRequiresIdBanner'),
        actions: [
          {
            label: $t('Add info'),
            handler: ({ dismiss }) => {
              dispatch('setUserIdentificationModal', {
                visible: true,
                onComplete: dismiss,
              })
            },
          },
        ],
      }
      dispatch('showBanner', banner, { root: true })
      return banner
    },

    checkIfOnShippingHold({ state, dispatch }) {
      if (!state.me.onShippingHold) return null
      const banner: BannerInfo = {
        id: 'user-shipping-hold',
        bgVariant: 'danger',
        title: $t('banners.shippingHold.title'),
        message: $t('banners.shippingHold.message'),
        actions: [
          {
            label: $t('Chat with us'),
            handler() {
              openZendesk()
            },
          },
          {
            label: $t('Call us'),
            href: tel.link(PILLPACK_PHONE),
          },
        ],
      }
      dispatch('showBanner', banner, { root: true })
      return banner
    },

    // if user is a caregiver, check for unverified relationships
    checkForUnresolvedCareRelationships({ state, dispatch }) {
      if (!state.me?.careReceivers?.length) return null
      return state.me.careReceivers
        .filter(relationship => relationship.status !== 'verified')
        .map(relationship => {
          const banner: BannerInfo = {
            id: `user-care-${relationship.id}`,
            bgVariant: 'primary',
            title: $t('banners.careRelationship.title'),
            message: $t('banners.careRelationship.message', {
              receiver: relationship.careReceiver.email,
            }),
            actions: [
              {
                label: $t('Verify'),
                handler() {
                  window.$location.redirect(`${getApiHost()}/signup/caregiver/${relationship.id}`)
                },
              },
            ],
          }
          dispatch('showBanner', banner, { root: true })
          return banner
        })
    },

    // if user has not finished hub checkout, redirect to signup/rx
    checkForUnfinishedHubCheckout({ state, getters }) {
      if (
        !Vue.$pillpack.isAdminMasquerading &&
        (!state.me.isAssumedUser || !state.me.caregivers.length) &&
        getters.hubIncomplete
      ) {
        window.$location.replace(`${getApiHost()}/signup/rx`)
      }
    },

    turnOnAutopay: trackAsyncStatus('user/turnOnAutopay', async function ({ commit }) {
      const autopayOn = await Vue.$pillpack.billing.turnOnAutopay()

      commit('setAutopay', { enabled: autopayOn })
    }),

    makeManualOrderPayment: trackAsyncStatus(
      'user/makeManualOrderPayment',
      async function ({ state, commit }, { overdueOnly }) {
        if (!state.me.account) return

        const account = await Vue.$pillpack.billing.makeManualOrderPayment({
          accountId: state.me.account.id,
          overdueOnly,
        })

        commit('setAccount', { account })
      },
    ),

    makeManualPartialPayment: trackAsyncStatus(
      'user/makeManualPartialPayment',
      async function ({ state, commit }, { payments }) {
        if (!state.me.account) return

        const account = await Vue.$pillpack.billing.makeManualPartialPayment({
          accountId: state.me.account.id,
          payments,
        })

        commit('setAccount', { account })
      },
    ),
  },

  getters: {
    hasUser: state => !!state.me.id,
    account: state => !!state.me.id && state.me.account,
    caregiverExists: state => (email: string) => {
      if (!state.me.caregivers) return false

      return state.me.caregivers.some(rel => rel.caregiverEmail === email)
    },
    currentUser: (state, getters): User | null => {
      return getters.hasUser ? state.me : null
    },
    authenticatedUser: (state, getters, rootState): User => {
      return rootState.clientState.isAssumedUser ? state.caregiver : state.me
    },
    careReceivers: (state, getters) => {
      const authenticatedUser = getters.authenticatedUser as User
      if (!authenticatedUser.id) return []
      if (!authenticatedUser.careReceivers) return []
      return authenticatedUser.careReceivers
    },
    verifiedReceivers: (state, getters) => {
      return getters.careReceivers.filter(
        (r: CareRelationship) => r.status === CaregiverStatus.Verified,
      )
    },
    hubIncomplete: state =>
      state.me?.hubCheckout?.isHubCheckout && !state.me.hubCheckout.isComplete,
  },
}

export default module
