















































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import HelpSearchCard from '@/components/HelpSearchCard.vue'
import EditGoPaperlessModal from '@/components/settings/EditGoPaperlessModal.vue'
import ModalSettingWrapper from '@/components/settings/ModalSettingWrapper.vue'
import User from '@/models/User'

@Component({
  components: {
    Card,
    PageHeader,
    LinkArrowIcon,
    HelpSearchCard,
    ModalSettingWrapper,
    EditGoPaperlessModal,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class GoPaperlessView extends Vue {
  isInProgress!: (key: string) => boolean
  me?: User
  hasSucceeded!: (key: string) => boolean

  get goPaperless(): boolean {
    return this.me?.preferences?.goPaperless === 'on'
  }

  get breadcrumbs() {
    return [
      {
        text: this.$t('pages.settings.title'),
        to: { name: 'settings' },
      },
      {
        text: this.$t('pages.settings.goPaperless.goPaperlessTitle'),
        to: { name: 'Go-paperless' },
      },
    ]
  }
}
