
















import { Component, Vue, Prop } from 'vue-property-decorator'

import PageTitle from '@/components/PageTitle.vue'
import Login from '@/components/Login.vue'
import { BannerInfo, MFA_BANNER_ID } from '@/vuex/banners'
import { $t } from '@/i18n'
import FeatureFlags from '@/util/featureFlags'
import amazonZendeskUrlFF from '@/util/amazonZendeskUrlFF'

@Component({
  components: { Login, PageTitle },
})
export default class LoginView extends Vue {
  async mounted() {
    const mfaBannerEnabled = await FeatureFlags.enabled('mfa-banner-enabled')
    const learnMoreUrl = amazonZendeskUrlFF(
      this.$t('verificationCode.amazonLearnMoreLink').toString(),
      this.$t('verificationCode.learnMoreLink').toString(),
    )

    if (mfaBannerEnabled) {
      const banner: BannerInfo = {
        id: MFA_BANNER_ID,
        bgVariant: 'primary',
        message: $t('verificationCode.mfaBanner'),
        hashyperlink: true,
        url: learnMoreUrl,
        urltext: $t('verificationCode.learnMore'),
      }
      this.$store.dispatch('showBanner', banner, { root: true })
    }
  }

  @Prop()
  redirectReason!: string

  get sessionTimedOut() {
    return this.redirectReason === 'sessionTimeout'
  }

  get apiHost() {
    return process.env.API_HOST
  }
}
