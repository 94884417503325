import { render, staticRenderFns } from "./MedicationForm.vue?vue&type=template&id=6cb5246f&scoped=true&"
import script from "./MedicationForm.vue?vue&type=script&lang=ts&"
export * from "./MedicationForm.vue?vue&type=script&lang=ts&"
import style0 from "./MedicationForm.vue?vue&type=style&index=0&id=6cb5246f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb5246f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BFormGroup } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
installComponents(component, {BFormGroup,BListGroup,BListGroupItem})
