import { serializable, alias, object, primitive } from 'serializr'
import { deserializeOnly } from '@/models/transforms'

class MFAPhoneNumber {
  @serializable(alias('last_section', deserializeOnly(primitive())))
  lastSection?: string
}

export default class TriggerMFAResponse {
  @serializable(alias('phone_number', deserializeOnly(object(MFAPhoneNumber))))
  phoneNumber?: MFAPhoneNumber

  get obfuscatedPhoneNumber() {
    const suffix = this.phoneNumber?.lastSection || 'xx'
    return `xxx-xxx-xx${suffix}`
  }
}
