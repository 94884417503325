

























import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import User from '@/models/User'
import Loading from '@/components/Loading.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import AddRxMedHeader from '@/components/medications/addRx/MedHeader.vue'
import { MedOption } from '@/vuex/addRx'

@Component({
  components: {
    Loading,
    LinkArrowIcon,
    AddRxMedHeader,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapState('addRx', ['med', 'medStrengthOptions']),
    ...mapGetters('addRx', ['medName']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class MedicationForm extends Vue {
  me!: User
  med!: MedOption
  medName!: string
  medStrengthOptions!: PrescriptionStrength[]

  @Watch('med', { immediate: true })
  onMedChange() {
    if (this.med?.type === 'NAME_ONLY') {
      this.getStrengths()
    }
  }

  formulations() {
    const seen = new Set()
    return this.medStrengthOptions
      .filter(s => {
        const formIncluded = !seen.has(s.form)
        seen.add(s.form)
        return formIncluded
      })
      .sort((a, b) => a.form!.localeCompare(b.form!))
  }

  getStrengths() {
    const userId = this.me.id
    this.$store.dispatch('addRx/loadMedStrengths', { userId })
  }

  select(selectedForm: string) {
    this.$store.commit('addRx/selectMedForm', { name: this.medName, form: selectedForm })
    this.$router.push({
      name: 'medication-strength',
    })
  }

  async created() {
    await this.$store.dispatch('user/ensureMe')
  }
}
