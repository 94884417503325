import PPCoreError from '@/models/PPCoreError'

export class PPError extends Error {
  readonly data?: any
  readonly errors?: PPCoreError[]
  readonly status?: number

  constructor(message?: string, data?: any) {
    super(message)
    this.data = data
  }
}

export class PPLogInFailedError extends PPError {
  readonly error: PPError
  constructor(error: PPError) {
    const message =
      (error && error.data && error.data.error) || 'Invalid email address or password.'
    super(message)
    this.error = error
  }
}

export class PPSessionResetError extends PPError {
  readonly error: PPError
  constructor(error: PPError) {
    super('Session has been reset.')
    this.error = error
  }
}

// PPMFAUnauthorizedError represents an error from the MFA APIs that indicates
// that the customer is not logged in.
export class PPMFAUnauthorizedError extends PPError {
  readonly error: PPError

  constructor(error: PPError) {
    const message = error?.data?.error || 'Unauthorized'
    super(message)
    this.error = error
  }
}

// PPMFAInvalidCodeError represents an error from the MFA Validation API that
// indicates that the customer entered an invalid code.
export class PPMFAInvalidCodeError extends PPError {
  readonly error: PPError

  constructor(error: PPError) {
    const message = error?.data?.error || 'Invalid or expired code'
    super(message)
    this.error = error
  }
}

// PPMFAAccountLockedError represents an error from the MFA APIs that
// indicates that the customer's account is locked due to repeated MFA attempts.
export class PPMFAAccountLockedError extends PPError {
  readonly error: PPError

  constructor(error: PPError) {
    const message = error?.data?.error || 'Account is locked'
    super(message)
    this.error = error
  }
}

// PPMFAOTPNotRequiredError indicates that OTP is not required for the current user,
// so they can skip MFA code verification.
export class PPMFAOTPNotRequiredError extends PPError {
  readonly error: PPError

  constructor(error: PPError) {
    const message = error?.data?.error || 'OTP not required'
    super(message)
    this.error = error
  }
}
