



























































































import { Component, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { TranslateResult } from 'vue-i18n'
import LoadingButton from '@/components/LoadingButton.vue'
import ChatLink from '@/components/ChatLink.vue'
import { validationStateMixin } from '@/util/validationState'
import amazonZendeskUrlFF from '@/util/amazonZendeskUrlFF'

const MFA_CODE_LENGTH = 6

@Component({
  components: { LoadingButton, ChatLink },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    mfaCode: {
      required,
      minLength: minLength(MFA_CODE_LENGTH),
      maxLength: maxLength(MFA_CODE_LENGTH),
    },
  },
})
export default class MultiFA extends Vue {
  mfaCode: string = ''
  loading: boolean = false
  validateError?: TranslateResult | null = null

  hasresendsuccess: boolean = false
  hasresenderror: boolean = false

  get learnMoreLink() {
    return amazonZendeskUrlFF(
      this.$t('verificationCode.amazonLearnMoreLink').toString(),
      this.$t('verificationCode.learnMoreLink').toString(),
    )
  }

  get mfaCodeMaxLength() {
    return MFA_CODE_LENGTH
  }

  get obfuscatedPhoneNumber(): string {
    return this.$store.getters.mfaObfuscatedPhoneNumber
  }

  async onSubmit() {
    this.loading = true
    this.validateError = null
    this.hasresendsuccess = false
    this.hasresenderror = false
    this.$v.$touch()

    if (this.$v.$invalid) {
      if (this.$v.mfaCode?.$invalid) {
        if (!this.$v.mfaCode?.required) {
          this.validateError = this.$t('verificationCode.invalidCode')
        } else {
          this.validateError = this.$t('verificationCode.invalidCode')
        }
      }
      this.loading = false
      return
    }

    const { mfaCode } = this

    try {
      const error = await this.$store.dispatch('validateMFACode', { mfaCode })
      if (error) {
        this.validateError = this.$t('verificationCode.invalidCode')
      } else {
        this.mfaCode = ''
        this.$v.$reset()
      }
    } catch (err) {
      Vue.$newrelic.noticeError(err as Error)
    } finally {
      this.loading = false
    }
  }

  async onResendCode() {
    this.mfaCode = ''
    this.validateError = ''
    this.$v.$reset()

    this.hasresendsuccess = false
    this.hasresenderror = false
    try {
      const error = await this.$store.dispatch('triggerMFATokenCreation')
      if (error) {
        this.hasresenderror = true
      } else {
        this.hasresendsuccess = true
      }
    } catch (err) {
      this.hasresenderror = true
      Vue.$newrelic.noticeError(err as Error)
    } finally {
      this.loading = false
    }
  }
}
