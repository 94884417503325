






import { Component, Vue } from 'vue-property-decorator'
import openAmazonConnectChat from '@/util/amazonChat'

@Component
export default class ChatLink extends Vue {
  async openChat(event: Event) {
    event.preventDefault()
    openAmazonConnectChat()
  }
}
