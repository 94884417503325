










import { Component, Vue } from 'vue-property-decorator'

import PageTitle from '@/components/PageTitle.vue'
import MultiFA from '@/components/MultiFA.vue'

@Component({
  components: { MultiFA, PageTitle },
})
export default class MultiFAView extends Vue {}
